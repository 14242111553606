import React, { useState, useEffect } from 'react';
import { Toaster } from "react-hot-toast";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './Layouts/Root';
import Preloader from './shared/Preloader/Preloader';

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />
    },
    {
      path: "*",
      element: <div>404</div>
    }
  ]
)

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);
  return (
    <>
      {
        loading ? <Preloader /> : (
          <RouterProvider router={router} />
        )
      }
      <Toaster />
    </>
  );
}

export default App;
