import React, { useContext } from 'react'
import { InitializeContext } from '../../Layouts/Root';
import Backend from './Backend';
import Frontend from './Frontend';
import "./Skills.css";

export default function Skills() {
  const { skills } = useContext(InitializeContext);
  return (
    <section ref={skills} className="skills section">
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle">My technical level</span>
      <div className="skills__container container grid">
        <Frontend />
        <Backend />
      </div>
    </section>
  )
}
