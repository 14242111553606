import React from 'react'
type Props = {
          item: any,
};

export default function WorkItems({ item }: Props) {
          return (
                    <div className="work__card">
                              <img src={item?.image} alt="" className="work__img" />
                              <h3 className="work__title">{item?.title}</h3>
                              <div className='work__links'>
                              <a href={item?.live} className="work__button-link" target="_blank" rel="noreferrer">
                              <i className='bx bx-link-external'></i> Live Site
                              </a>
                              {item?.client && (<a href={item?.client} className="work__button-link" target="_blank" rel="noreferrer"><i className='bx bx-link'></i> Client</a>)}
                              {item?.server && (<a href={item?.server} className="work__button-link" target="_blank" rel="noreferrer"><i className='bx bx-link'></i> Server</a>)}
                              </div>
                    </div>
          )
}
