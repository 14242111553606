import Work1 from '../../assets/works/gitdown.png';
import Work2 from '../../assets/works/paste.png';
import Work3 from '../../assets/works/mealpanda.png';
import Work4 from '../../assets/works/compiler.png';
import Work5 from '../../assets/works/phonehunter.png';
import Work6 from '../../assets/works/bugatti.png';
import Work7 from '../../assets/works/influencer.png';
import Work8 from '../../assets/works/kazitranslator.jpg';
import Work9 from '../../assets/works/gitdown-app.jpg';
import Work10 from '../../assets/works/agency.png';
import Work11 from '../../assets/works/thbd-bn.png';
import Work12 from '../../assets/works/thbd-en.png';
import Work13 from '../../assets/works/buyerscouncil.png';
import Work14 from '../../assets/works/birthday.png';
import Work15 from '../../assets/works/satisfyhost-blog.png';

export const projectsData = [
    {
        id: 1,
        image: Work1,
        title: "GitDown",
        category: "MERN stack",
        live: "https://techhelpbd.com/gitdown",
    },
    {
        id: 2,
        image: Work2,
        title: "Paste",
        category: "MERN stack",
        live: "https://techhelpbd.com/paste",
    },
    {
        id: 3,
        image: Work3,
        title: "Meal Panda",
        category: "frontend",
        live: "https://mealpanda-react.netlify.app/",
        client: "https://github.com/NoobMahbub/mealpanda",
    },
    {
        id: 4,
        image: Work4,
        title: "Compiler",
        category: "MERN stack",
        live: "https://compiler-nu.vercel.app",
    },
    {
        id: 5,
        image: Work5,
        title: "Phone Hunter",
        category: "frontend",
        live: "https://noobmahbub.github.io/phone-hunter/",
        client: "https://github.com/NoobMahbub/phone-hunter",
    },
    {
        id: 6,
        image: Work6,
        title: "Bugatti Sports Car Store",
        category: "frontend",
        live: "https://bugatti-sports-car-store.netlify.app",
        client: "https://github.com/NoobMahbub/bugatti-sports-car-store",
    },
    {
        id: 7,
        image: Work7,
        title: "Influencer Gear",
        category: "frontend",
        live: "https://noobmahbub.github.io/influencer-gear/",
        client: "https://github.com/NoobMahbub/influencer-gear",
    },
    {
        id: 8,
        image: Work8,
        title: "Kazi Translator",
        category: "React Native",
        live: "https://play.google.com/store/apps/details?id=com.techhelpbd.kazitranslator",
    },
    {
        id: 9,
        image: Work9,
        title: "GitDown App",
        category: "React Native",
        live: "https://play.google.com/store/apps/details?id=com.techhelpbd.gitdown",
    },
    {
        id: 10,
        image: Work10,
        title: "Agency Portfolio",
        category: "frontend",
        live: "https://techhelpbd.netlify.app/",
    },
    {
        id: 11,
        image: Work11,
        title: "Tech Help BD Blog (Bangla)",
        category: "WordPress",
        live: "https://techhelpbd.com",
    },
    {
        id: 12,
        image: Work12,
        title: "Tech Help BD Blog (English)",
        category: "WordPress",
        live: "https://techhelpbd.com/en",
    },
    {
        id: 13,
        image: Work13,
        title: "Buyers Council",
        category: "Laravel",
        live: "https://laravel.techhelpbd.com",
    },
    {
        id: 14,
        image: Work14,
        title: "Birthday Wish Website",
        category: "frontend",
        live: "https://hbdisha.vercel.app",
    },
    {
        id: 15,
        image: Work15,
        title: "SatisfyHost Blog",
        category: "WordPress",
        live: "https://satisfyhost.com/blog",
    },
    
];

export const projectsNav = [
    {
        name: "all",
    },
    {
        name: "MERN stack",
    },
    {
        name: "frontend",
    },
    {
        name: "React Native",
    },
    {
        name: "WordPress",
    },
    {
        name: "Laravel",
    }
];