import { useEffect, useState } from "react";

export default function useTitle(titleText: any) {
  const [title, setTitle] = useState("");
  useEffect(() => {
    document.title = "Kazi Mahbubur Rahman - Full Stack Developer";
    setTitle(titleText);
    console.log(titleText);
  }, [titleText]);
  return [title];
};
